import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Global } from "@emotion/react"
import { css } from 'theme-ui'
import pageContextProvider from "@helpers/pageContextProvider"
import Header from "./header"
import Footer from "./footer"
import "../styles/global.scss"

const Layout = ({ children, style, pageContext, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <pageContextProvider.Provider value={{ pageContext, location }}>
      <Global styles={css(theme => theme.global)} />
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: `2rem 0`,
            maxWidth: 1080,
            width: "100%",
            padding: `0 1.5rem 1.5rem`,
            flex: "1 0 auto",
            justifyContent: "center",
            alignItems: "center",
            ...style,
          }}
        >
          <main>{children}</main>
        </div>
        <Footer />
      </div>
    </pageContextProvider.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
