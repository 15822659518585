import { Link } from "gatsby"
import React from "react"
import logoIMG from "../../content/assets/mm_long_logo.svg"
import { useAuth } from "gatsby-theme-firebase"
import { Box } from 'theme-ui'

const Header = () => {
  const { isLoggedIn, isLoading } = useAuth()

  const menuLinks = [
    {
      name: "Academy",
      link: "/academy",
    },
    {
      name: "Blog",
      link: "/blog",
    },
    {
      name: "Podcast",
      link: "/podcast",
    },

    {
      name: "Community",
      link: "/community",
    },
    {
      name: "Account",
      link: "/account",
    },
  ]

  const styles = {
    wrapper: {
      bg: `headerBg`
    }
  }

  return (
    <Box className="top-header" sx={styles.wrapper}>
      <Link className="main-logo" to={`/`}>
        <div className="title-logo">
          <img src={logoIMG} height="200px" />
        </div>
      </Link>
      <nav>
        <input type="checkbox" id="hamburger1" />
        <label htmlFor="hamburger1"></label>
        <ul className="nav-links">
          {menuLinks.map(link => {
            if (link.link.startsWith("http"))
              return (
                <li key={link.name}>
                  <a href={`${link.link}`}>{link.name}</a>
                </li>
              )
            else
              return (
                <li key={link.name}>
                  <Link to={`${link.link}`}>{link.name}</Link>
                </li>
              )
          })}
        </ul>
      </nav>
    </Box>
  )
}

export default Header
