import React, { useState, useEffect } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  Hint,
} from "react-vis"

export default () => {
  const [subscriptionPrice, setSubscriptionPrice] = useState(49)
  const [monthlyUsers, setMonthlyUsers] = useState(10)
  const [churnRate, setChurnRate] = useState(10)
  const [crosshairValues, setcrosshairValues] = useState(null)
  const [DATA, setDATA] = useState([])

  useEffect(() => {
    calcMRR()
  }, [subscriptionPrice, monthlyUsers, churnRate])

  const _onNearestX = (value, { index }) => {
    setcrosshairValues(DATA[index])
  }

  const calcMRR = () => {
    let newDATA = []
    for (let m = 1; m < 37; m++) {
      const thisMonth = m - 1
      const churnPercent = churnRate / 100
      let mrr =
        subscriptionPrice *
        monthlyUsers *
        (1 + thisMonth - thisMonth * churnPercent)
      mrr = mrr > 0 ? mrr.toFixed(0) : 0
      newDATA.push({ x: m, y: Number(mrr) })
    }

    setDATA(newDATA)
    // return Math.trunc(mrr).toLocaleString()
  }

  return (
    <Layout>
      <SEO
        title="MRR Calculator"
        description="See how fast your Monthly Recurring Revenue (MRR) could grow."
      />
      <h1 style={{ textAlign: "center" }}>
        Monthly Recurring Revenue (MRR) Calculator
      </h1>
      <div className="mrr-calc">
        <div className="tool-inputs">
          <div className="row">
            <div className="column50">
              <h3>What is Your Subscription Price?</h3>
            </div>
            <div className="column50 input-side">
              <span>$&nbsp;</span>
              <input
                min="1"
                placeholder="$49"
                type="number"
                value={subscriptionPrice}
                className="tool-input"
                onChange={e => setSubscriptionPrice(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="column50">
              <h3>How Many Customers Do You Gain Per Month?</h3>
            </div>
            <div className="column50 input-side">
              <input
                placeholder="10"
                min="1"
                type="number"
                value={monthlyUsers}
                className="tool-input"
                onChange={e => setMonthlyUsers(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="column50">
              <h3>What is Your Churn Rate?</h3>
              <p>What percentage of your customers do you lose each month?</p>
            </div>
            <div className="column50 input-side">
              {/* <span>&lt;1</span> */}
              <div className="slidecontainer">
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={churnRate}
                  className="tool-slider"
                  onChange={e => setChurnRate(e.target.value)}
                />
                <output
                  className="bubble"
                  style={{
                    left: `calc(${Number(
                      ((churnRate - 0) * 100) / (100 - 0)
                    )}% + (${8 -
                      Number(((churnRate - 0) * 100) / (100 - 0)) * 0.15}px))`,
                  }}
                >
                  {churnRate}%
                </output>
              </div>
            </div>
          </div>
        </div>

        <div className="tool-results">
          <h2>Your MRR Growth Potential:</h2>
          <div className="final-calc">
            <FlexibleXYPlot
              margin={{ left: 50 }}
              className="graph-holder"
              height={300}
              onMouseLeave={() => setcrosshairValues(null)}
            >
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis />
              <YAxis
                tickFormat={tick => {
                  if (tick > 999999) return `$${tick / 1000000}M`
                  if (tick > 999) return `$${tick / 1000}k`
                  else return `$${tick}`
                }}
              />
              <VerticalBarSeries
                onNearestX={_onNearestX}
                data={DATA}
                style={{ fill: "#28559e", strokeWidth: 0 }}
              />
              {crosshairValues ? (
                <Hint value={crosshairValues}>
                  {" "}
                  <div className="rv-hint__content">
                    {`Month ${crosshairValues.x}:  $${Math.trunc(
                      crosshairValues.y
                    ).toLocaleString()}`}
                  </div>
                </Hint>
              ) : null}
            </FlexibleXYPlot>
            <table className="mrr-table">
              <thead>
                <tr>
                  <th scope="col">Month&nbsp;1</th>
                  <th scope="col">Month&nbsp;2</th>
                  <th scope="col">Month&nbsp;3</th>
                  <th scope="col">Month&nbsp;4</th>
                  <th scope="col">Month&nbsp;5</th>
                  <th scope="col">Month&nbsp;6</th>
                  <th scope="col">Year&nbsp;1</th>
                  <th scope="col">Year&nbsp;2</th>
                  <th scope="col">Year&nbsp;3</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {DATA.length ? (
                    [1, 2, 3, 4, 5, 6, 12, 24, 36].map(m => (
                      <td
                        key={m}
                        data-label={
                          m % 12 == 0 ? `Year ${m / 12}` : `Month ${m}`
                        }
                      >
                        ${Math.trunc(DATA[m - 1].y).toLocaleString()}
                      </td>
                    ))
                  ) : (
                    <td></td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  )
}
